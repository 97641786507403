import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.project',
      search: 'project.search_keyword',
      filter: {
        status: {
          label: 'data.status',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data.status',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'project.data.capacity', key: 'capacity' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'photos',
            label: 'data.photo',
            type: 'photo',
            photo: (row) => this.vm?.$helper.getMasterPhoto(row.photos),
          },
          {
            key: 'name',
            label: 'data.name',
            copyable: true,
            type: 'text',
          },
          {
            key: 'capacity',
            label: 'project.data.capacity',
            type: 'text',
            text: row => this.vm?.$helper.getCapacityHumans(row.capacity),
          },
          {
            key: 'type',
            label: 'project.data.type',
            type: 'text',
            text: row => this.vm?.$t(`project.data.type.${row.type}`),
          },
          {
            key: 'status',
            label: 'data.status',
            type: 'switch',
          },
          {
            key: 'location',
            label: 'data.location',
            type: 'text',
            text: row => `${row.city}${row.district}`
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      create: {
        popupComponent: () => import('@/modules/project/views/projectList/projectListCreatePopup.vue'),
        disabledApply: (formData) => {
          if(window.helper.textEmpty(formData.name)) return true
          if(window.helper.textEmpty(formData.type)) return true
          if(window.helper.textEmpty(formData.city)) return true
          if(window.helper.textEmpty(formData.district)) return true
          return false
        },
        createApi: async (formData) => {
          return await this.vm?.$api.collection.projectApi.create(formData)
        },
        redirect: (result => ({
          name: `project-update`,
          params: { target: result.id },
        }))
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'project-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
